<template>
    <div v-if="impersonate === true" class="z-[999999999999999999]">
        <div class="fixed h-16 animated-gradient flex items-center justify-center text-white font-bold text-2xl select-none w-full gap-3">
            <icons-exclamation-outline class="w-11"></icons-exclamation-outline>
            Prise de contrôle d'un compte client
            <icons-exclamation-outline class="w-11"></icons-exclamation-outline>

            <c-button class="absolute right-2 border" @click="logout">
                Déconnexion
            </c-button>
        </div>

        <div class="h-16"></div>
        <div class="fixed w-[100vw] h-[100vh] border-x-4 border-b-4 border-red pointer-events-none"></div>
    </div>
</template>

<script setup>
import {useUserStore} from "~/store/user";

const impersonate = computed(() => {
    return useUserStore().impersonate;
});

const axios = useNuxtApp().$axios;

function logout() {
    useUserStore().logout();

    axios.get("/logout").then(() => {
        if (confirm("Souhaitez-vous fermer l'onglet ?") === true) {
            window.close();
        }
    });
}
</script>

<style scoped lang="scss">
.animated-gradient {
    animation: animateBg 2s linear infinite;
    background-image: linear-gradient(90deg, #ff0000, #ff5757, #ff0000, #ff5757);
    background-size: 300% 100%;
}

//le bg c'est qui c'est moi et oui mon pote
@keyframes animateBg {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}
</style>
